
.langPage {
  background-color: black;
  opacity: .8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 30vh;
  font-size: 10vh;
}

.langPage.hidden {
  display: none;
}

.langPage a {
  color: white;
}

.langButton {
  float: right;
  border: solid;
  border-radius: 20px;
  margin: 3px 10px;
  padding: 4px 20px;
}

.navbutton:hover, .langButton:hover, .navbutton > a:hover {
  color: black !important;
  background-color: white !important;
}

.navbutton > a {
  color: inherit !important;
  transition: unset;
}

.navbar {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.navbar, .navbar * {
  transition: all 0.5s ease;
}