.headerImage {
  width: 100%;
  max-height: 50vh;
}

.header {
  background-color: #000;
  color: #f44333;
  padding-top: 64px;
  padding-bottom: 32px;
  min-height: 100vh;
  text-align: center;
}

.headerName {
  font-size: 64px;
}

.headerText {
  font-size: 24px;
}

.commonDiv {
  padding-top: 64px;
  padding-bottom: 64px;
}

.oddDiv {
  background-color: #f1f1f1;
}

.evenDiv {
  background-color: #fff;
}

.rowMulti {
  margin-bottom: 70px;
}

.center {
  text-align: center;
}

.fontImage {
  font-size: 200px;
  text-align: center;
}

.fontImageSmall {
  font-size: 100px;
  text-align: center;
}

.margin1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}